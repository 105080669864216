import React, {useEffect, useState} from 'react';
import Spinner from 'react-spinkit';

function BlogEntry(props) {
	const { title, cover, iframe } = props.post;
	const [showIframe, setShowIframe] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const hideSpinner = () => {
		setLoading(false);
	}

	useEffect(() => {
		if (showIframe) {
			const iframe = document.getElementsByClassName(cover.split('.')[0])[0].firstChild;
			if (iframe) {
				iframe.addEventListener('load', hideSpinner);
			}
		} else {
			setLoading(true);
		}
	}, [showIframe, cover])

	return (
		<article>
			<div className='post-wrapper'>
				<section className='post'>
					<div className='photo-wrapper'>
						<div onClick={() => setShowIframe(!showIframe)} className='photo-wrapper-inner'>
							<img src={`/covers/${cover}`} alt={title} height='1280' width='1280' />
						</div>
					</div>
					{showIframe && (
						<figcaption className='caption'>
							{isLoading && (
								<div className='spinner-wrapper'>
									<Spinner name='ball-scale-multiple' />
								</div>
							)}
							<div className={cover.split('.')[0]} dangerouslySetInnerHTML={{__html: iframe}}></div>
						</figcaption>
					)}
				</section>
			</div>
		</article>
	)
}

export default BlogEntry;
