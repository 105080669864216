import React, { useEffect, useState, useMemo } from 'react';
import './App.css';
import logo from './assets/logo.jpg';
import BlogEntry from './components/BlogEntry';
import posts from './posts.json';


function App() {
	const currentURL = window.location.pathname;
	const [currentPath, setCurrentPath] = useState(currentURL);
	const [fixedMenu, setFixedMenu] = useState(false);
	const [showPosts, setShowPosts] = useState(posts);
	const [activeMenu, setActiveMenu] = useState('HOME');

	const menu = useMemo(() => {
		return [
			{title: 'ALL', link: '/'},
			{title: 'MIXTAPES', link: '/mixtapes'},
			{title: 'HERMIT VAN DYKE', link: '/hermit-van-dyke'},
			{title: 'FLYING FELT PADS', link: '/flying-felt-pads'},
			{title: 'ARISTOCATS', link: '/aristocats'},
			{title: 'DIESMALSO', link: '/diesmalso'}
		]},
	[])

	useEffect(() => {
		const menuItem = menu.find(m => m.link === currentPath)
		if (!menuItem) {
			setCurrentPath('/');
			return;
		}
		setActiveMenu(menuItem.link);
		window.history.pushState({}, '', menuItem.link);
		let filteredPosts
		if (currentPath === '/') {
			filteredPosts = posts
		} else {
			filteredPosts = posts.filter(post => post.tags.some(tag => tag.toLowerCase() === menuItem.title.toLowerCase()));
		}
		setShowPosts(filteredPosts);
	}, [currentPath, menu]);

	const checkElementInViewport = () => {
		const element = document.getElementById('header-wrapper');
		const position = element.getBoundingClientRect();

		if(position.bottom >= 0) {
			setFixedMenu(false);
		} else {
			setFixedMenu(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', checkElementInViewport);

		return () => {
				window.removeEventListener('scroll', checkElementInViewport);
		};
	}, []);

  return (
    <div className='App'>
      <section>
        <header>
          <div id='header-wrapper' className='header-image-wrapper contain'>
            <a className='header-image' href='/'>
              <img src={logo} alt='Radio Borowski Logo' />
            </a>
          </div>
        </header>
        <div className={`nav-wrapper ${fixedMenu ? 'nav-fixed' : ''}`}>
          <nav>
            <ul className='inline-nav'>
							{menu.map(menuItem => {
								return (
									<li className='nav-item' key={menuItem.title}>
										<div onClick={() => setCurrentPath(menuItem.link)} className={`label ${menuItem.link === activeMenu ? 'current-page' : ''}`}>{menuItem.title}</div>
									</li>
								)
							})}
            </ul>
          </nav>
        </div>
				<section className='content'>
					<div className='container'>
						<div className='main'>
							{showPosts.map((post) => <BlogEntry key={post.title} post={post} />)}
						</div>
					</div>
				</section>
      </section>
    </div>
  );
}

export default App;
